import { useStaticQuery, graphql } from 'gatsby';

export const useResourcesTeam = () => {
    const ResourcesTeam = useStaticQuery(
        graphql`
            query GET_RESOURCE_TEAM {
                allWordpressPost(
                    filter: {
                        categories: {
                            elemMatch: { slug: { eq: "resources-team" } }
                        }
                    }
                ) {
                    nodes {
                        excerpt
                        slug
                        id
                        excerpt
                        title
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return ResourcesTeam.allWordpressPost.nodes;
};
